import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FilterBuilder, { CustomOperation, FilterBuilderTypes, ICustomOperationProps } from 'devextreme-react/filter-builder';
import { dxElement } from 'devextreme/core/element';
import dxFilterBuilder from 'devextreme/ui/filter_builder';
import { SelectBox } from 'devextreme-react';
import { CustomItemCreatingEvent, ValueChangedEvent } from 'devextreme/ui/select_box';
import { IDataItemProps } from '@liasincontrol/ui-basics';

export type FilterValueType = string | Array<any> | Function;

export type LsFilterBuilderField = {
    caption?: string,
    dataField?: string,
    dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime',
    filterOperations?: Array<'=' | '<>' | '<' | '<=' | '>' | '>=' | 'contains' | 'endswith' | 'isblank' | 'isnotblank' | 'notcontains' | 'startswith' | 'between' | string>,
};

export type LsFilterBuilderOnChangeHandler = {
    component?: dxFilterBuilder,
    element?: dxElement,
    model?: any,
    value?: FilterValueType,
    previousValue?: FilterValueType,
};

export interface ILsFilterBuilderProps {
    readonly id: string;
    readonly fields: LsFilterBuilderField[] | undefined;
    readonly value: FilterValueType;
    readonly disabled?: boolean;
    readonly locale?: string;
    readonly variables?: IDataItemProps<string>[];
    readonly onValueChanged: (object) => void;
}

export const LsFilterBuilder: React.FC<ILsFilterBuilderProps> = (props) => {
    const [dataSourceText, setDataSourceText] = useState(props.value); // send out filter value with "=" 
    const fields = useMemo(() => props.fields.map(f => f.dataType === "string" ? ({ ...f, filterOperations }) : f), [props.fields]);
    const [localValue, setLocalValue] = useState(updateFilterValue(props.value, fields)); // receive filter value with '=='

    useEffect(() => {
        // send out filter value with "=" 
        props.onValueChanged(dataSourceText);
    }, [dataSourceText]);

    const updateTexts = useCallback((e: FilterBuilderTypes.InitializedEvent) => {
        setDataSourceText(e.component.getFilterExpression() as string);
    }, [setDataSourceText]);

    const onValueChanged = useCallback((e: FilterBuilderTypes.ValueChangedEvent) => {
        setLocalValue(e.value)
        updateTexts(e);
    }, [updateTexts, props.onValueChanged]);

    return (
        <FilterBuilder
            id={props.id}
            value={localValue}
            fields={fields}
            onValueChanged={onValueChanged}
            disabled={props.disabled}
        >
            <CustomOperation
                name="=="
                caption="Gelijk aan"
                icon="filter"
                calculateFilterExpression={calculateFilterExpression}
                editorComponent={({ data }) =>
                    <EditorComponent
                        {...data}
                        dataSource={props.variables}
                    />
                }
            />
        </FilterBuilder>
    );
};

export const EditorComponent = (props) => {
    const onValueChanged = useCallback((e: ValueChangedEvent) => {
        props.setValue(e.value);
    }, [props.setValue]);

    const onCustomItemCreating = useCallback((e: CustomItemCreatingEvent) => {
        if (e.text) {
            e.customItem = { label: e.text, value: e.text };
            props.setValue(e.text)
        } else {
            e.customItem = {};
        }
    }, [props.setValue])

    return (
        <SelectBox
            acceptCustomValue={true}
            displayExpr="label"
            valueExpr="value"
            defaultValue={props.value}
            items={props.dataSource}
            onValueChanged={onValueChanged}
            onCustomItemCreating={onCustomItemCreating}
        />
    );
};

/**
 * How does string filtering work?
 * 
 * Since we introduced the publication variables, we support filtering with manual input
 * and with a selector that let's you pick a pre-defined variable. To achieve that using DevExtreme FilterBuilder
 * we need to define a custom operation ("==") and a custom editor component. 
 * 
 * Why we need the custom operation?
 * Because previously we were using the custom editor for the implicit "=" operation, however we had production issue
 * where boolean fields and number fields were using the same custom editor instead of the default one, so the data was not
 * saved properly. This is why we have to use a custom operation that we handle specifically where we want.
 * 
 * The headache with using custom operation is that it is a mere workaround for the '=' operation. What we actually need is still
 * the '=' operation outside the FilterBuilder, so we need to manage what gets in the FilterBuilder and what gets out. 
 * The '==' operation is just an intermediary but necessary phase. 
 * 
 * What happens when you edit a filter value?
 * 
 * First, FilterBuilder's onValueChanged callback is fired. We don't propagate that value upwards right away
 * because if the operation used is "==", the "==" must be converted to "=". This conversion is done right after onValueChanged by
 * calculateFilterExpression and updateTexts. Now, the data that will be saved will have filter expressions with '=', not '=='.
 * If you open the filterBuilder again, you will receive a filter expression with '='. But the '=' operation is not defined in filterOperations
 * so DevExtreme FilterBuilder will complain about it, right? Right, and this is why we initialize the localState with updateFilterValue 
 * which converts from '=' to '==' so the FilterBuilder will be happy.
 */
const filterOperations = ["==", "contains", "notcontains", "startswith", "endswith", "<>", "isblank", "isnotblank"];

const calculateFilterExpression: ICustomOperationProps['calculateFilterExpression'] = (filterValue, field) => {
    return [
        field.dataField,
        "=",
        filterValue
    ];
};

/**
 * For all string fields that appear in the filter value, converts all '=' operations to '==' operations.
 * @param filterValue The filter value as array of strings or array of arrays of strings
 * @param fields An array of all fields of the control 
 */
const updateFilterValue = (filterValue: FilterValueType, fields: LsFilterBuilderField[]): FilterValueType => {
    if (!Array.isArray(filterValue)) {
        return filterValue; // Return unchanged if it's not an array
    }

    const processFilter = (filter: FilterValueType): FilterValueType => {
        if (Array.isArray(filter)) {
            return filter.map(item => {
                if (Array.isArray(item) && item.length === 3) {
                    const [field, operator, value] = item;
                    const matchingField = fields.find(f => f.dataField === field);

                    if (matchingField && matchingField.dataType === 'string' && operator === '=') {
                        return [field, '==', processFilter(value)];
                    }
                } 
                return processFilter(item);
            });
        }
        
        return filter;
    }

    // skip recursion if filter has only one condition (then it is an Array<string>, not Array<string | Array | Function>)
    const firstCondition = filterValue[0];
    if (!Array.isArray(firstCondition)) {
        const field = fields.find(f => f.dataField === firstCondition);
        if (field && field.dataType === 'string' && filterValue[1] === '=') {
            const filterValueCopy = [...filterValue];
            filterValueCopy[1] = '==';
            return filterValueCopy;
        }
        return filterValue;
    }

    return processFilter(filterValue);
}
