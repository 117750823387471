import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Wrapper, WrapperContent, PageTitle, Heading2, PanelGrid, palette } from '@liasincontrol/ui-basics';
import { TaskTiles, TaskSummary, TextImageWidget, Announcement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, State, AjaxRequestStatus, MeasureMomentsActionCreator, useAnnouncement } from '@liasincontrol/redux-service';
import { License, UserIdentity } from '@liasincontrol/auth-service';
import { Shared as PerformanceShared } from '@liasincontrol/performance';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import Tiles from './Tiles';
import { ErrorOverlay } from '@liasincontrol/ui-elements';

/**
 * Defines the props of the Homepage view component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the Homepage view.
 */
const Homepage: React.FC<Props> = (props) => {
    const [taskSummary, setTaskSummary] = useState<TaskSummary[]>([]);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const navigate = useNavigate();

    //only textassistant => redirect?
    if (props.userIdentity?.profile?.licenses.length === 1 && UserRightsService.getInstance().userHasLicence(props.userIdentity, License.AITextAssistant)) {
        navigate('/ai');
    }

    const announcement = useAnnouncement();
    const hasManageUserAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read);

    let adminUrl: string | undefined = undefined;
    if (!adminUrl && hasManageUserAccess) {
        adminUrl = "/admin/usermanagement/users";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_DataStores, ActionType.Read)) {
        adminUrl = "/admin/datastore/list";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Workflows, ActionType.Read)) {
        adminUrl = "/admin/workflow/list";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_MeasureMoments, ActionType.Read)) {
        adminUrl = "/admin/measuremoment/list";
    }

    const showPerformanceCharts = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_ShowDashboardCharts, ActionType.Read);
    const hasPublisherAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Read);

    useEffect(() => {
        if (hasPublisherAccess) {
            DataAccess.WorkflowTask.getWorkflowTaskSummary()
                .then((response) => {
                    setTaskSummary(response.data.map((dto: Domain.Publisher.WorkflowTaskSummary) => ({
                        closedTaskCount: dto.closedTaskCount,
                        openActiveTaskCount: dto.openActiveTaskCount,
                        openInactiveTaskCount: dto.openInactiveTaskCount,
                        title: dto.publicationName,
                        link: `/publisher/publication/${dto.publicationId}/writer/page/`,
                        color: palette.primary2,
                        key: `pub-${dto.publicationId}`
                    })));
                })
                .catch((err) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
                });
        }
    }, [lastRefresh]);

    const onRetry = () => {
        if (!error?.canRetry) {
            return;
        }
        setError(undefined);
        setLastRefresh(Date.now());
    };

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementdefinitions[ActionSource.Publication] || props.elementdefinitions[ActionSource.Publication].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher], ActionSource.Publication);
        return null;
    }

    if (!props.elementdefinitions[ActionSource.Performance] || props.elementdefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance], ActionSource.Performance);
        return null;
    }


    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Welkom {props.userIdentity?.profile?.preferred_username || ''}</Heading2>
                    {announcement.items?.message &&
                        <Announcement title='Mededeling' announcement={announcement.items?.message} renderAs='widget' />
                    }
                </PageTitle>
                <div className="view-wrap">
                    <PanelGrid>
                        <TextImageWidget
                            title='Welkom bij LIAS InControl'
                            text='Maak, beheer en controleer beleidsdocumenten gezamenlijk online. Stel doelen, analyseer resultaten
                                en maak de rapportage inzichtelijk voor iedereen – van beleidsmedewerkers tot raadsleden en burgers.'
                        />

                        <Tiles
                            administrativeUrl={adminUrl}
                            showManageUsers={hasManageUserAccess}
                            hasPublisherAccess={hasPublisherAccess}
                        />

                        {/* Don't nest the TaskTiles inside the ErrorOverlay, otherwise it will mess up the overall PanelGrid layout */}
                        {error?.message
                            ? <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={onRetry} onBack={error?.canGoBack ? () => setError(undefined) : undefined} />
                            : hasPublisherAccess ? <TaskTiles title='Taken binnen Publisher' tasks={taskSummary} rowspan={3} /> : null
                        }
                        {showPerformanceCharts && props.elementdefinitions[ActionSource.Performance]?.items &&
                            <PerformanceShared.PerformanceCharts
                                elementDefinitions={props.elementdefinitions[ActionSource.Performance]?.items}
                                measureMoments={props.measureMoments.items} />
                        }
                    </PanelGrid>
                </div>
            </WrapperContent>
        </Wrapper>
    );
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementdefinitions: state.elementdefinitions,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module, actionSource: ActionSource) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id } }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
