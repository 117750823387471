import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { cssFontBold, px } from '../style';

const transition = { ease: "easeOut", duration: 0.3 };

export interface IEdgeToolbarProps {
    readonly children?: React.ReactNode;
    readonly $edge: 'left' | 'top' | 'right' | 'bottom';
    readonly $open?: boolean;
    readonly $inModal?: boolean;
    readonly onToggle?: () => void;
    readonly $look?: 'default' | 'editor';
    readonly $withTabs?: boolean;
}

const BaseEdge = styled(motion.div) <IEdgeToolbarProps>`
    position: ${(p) => p.$inModal ? 'absolute' : 'fixed'};
    background: ${(p) => (p.$look === 'default' && '#FFF') || (p.$look === 'editor' && '#4B55F6')};
`;

const TopEdge = styled(BaseEdge)`
    box-shadow: 0 3px 20px 0 rgba(75, 85, 246, 0.1);
    left: 0;
    right: 0;
    top: 0;
    bottom: auto;
    padding: 16px 48px;
`;

const RightEdge = styled(BaseEdge)`
    box-shadow: -5px 0 20px 0 rgba(75, 85, 246, 0.1);
    left: auto;
    right: 0;
    top: 0;
    bottom: 0;
`;

const BottomEdge = styled(BaseEdge)`
    box-shadow: 0 -3px 20px 0 rgba(75, 85, 246, 0.1);
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    padding: 14px 48px;
`;

const LeftEdge = styled(BaseEdge)`
    left: 0;
    right: auto;
    top: 0;
    bottom: 0;
`;

const TabHolder = styled.div<{ $isOpen: boolean, $withTabs?: boolean }>`
    position: absolute;
    top: ${px(20)};
    left:${(p) => p.$isOpen ? `${px(500)}` : (p.$withTabs ? `${px(50)}` : `${px(0)}`)};
`;

const MenuTab = styled.div<{ $isOpen: boolean }>`
    position: relative;
    width: ${(p) => p.$isOpen ? '30px' : 'auto'};
    max-width: 200px;
    padding: 4px 15px;
    border-radius: 0 4px 4px 0;
    background-color: #4b55f6;
    transition: max-width 0.3s;

    :hover { 
        cursor: pointer; 
    }

    > span {
        ${cssFontBold(11, 11, 100)}
        padding-right: 16px;
        display: inline-block;
        overflow: hidden;
        text-transform: uppercase;
        color: #fff;
        width: ${(p) => p.$isOpen ? '1px' : 'auto'};
        opacity: ${(p) => p.$isOpen ? '0' : '1'};
        transition: width 0.3s, opacity 0.3s;
    }
    
    > .MuiSvgIcon-root {
        position: absolute;
        top: 3px;
        right: 5px;
        width: 20px;
        height: 20px;
        fill: #fff;
    }
`;

// Animation variants
const topVariants = {
    opened: { y: "0%", opacity: 1, transition },
    closed: { y: "-100%", opacity: 0, transition }
};

const rightVariants = {
    opened: { x: "0%", opacity: 1, transition },
    closed: { x: "100%", opacity: 0, transition }
};

const bottomVariants = {
    opened: { y: "0%", opacity: 1, transition: { ...transition, delay: 0.3 } },
    closed: { y: "100%", opacity: 0, transition }
};

const leftVariants = ($withTabs: boolean) => ({
    opened: {
        clipPath: "none",
        boxShadow: "5px 0 20px 0 rgba(75, 85, 246, 0.1)",
        transition
    },
    closed: {
        clipPath: $withTabs ? "inset(0 450px 0 0)" : "inset(0 500px 0 0)",
        boxShadow: $withTabs ? "5px 0 20px 0 rgba(75, 85, 246, 0.1)" : "none",
        transition
    }
});

export const EdgeToolbar = ({
    $edge,
    $open = true,
    children,
    $inModal,
    onToggle,
    $withTabs = false,
    $look = 'default'
}: IEdgeToolbarProps) => {
    const chevronToggleIcon = $open
        ? <KeyboardArrowLeftIcon fontSize="small" />
        : <KeyboardArrowRightIcon fontSize="small" />;

    const tab = onToggle && (
        <TabHolder $isOpen={$open} $withTabs={$withTabs}>
            <MenuTab $isOpen={$open} onClick={onToggle} role="button">
                <span>Menu</span>
                {chevronToggleIcon}
            </MenuTab>
        </TabHolder>
    );

    const commonProps = {
        className: "edge-toolbar",
        $look,
        $initial: "closed",
        animate: $open ? "opened" : "closed",
        $edge,
        $open,
        $inModal,
    };

    switch ($edge) {
        case 'top':
            return (
                <TopEdge
                    {...commonProps}
                    variants={topVariants}
                >
                    {children}
                </TopEdge>
            );
        case 'right':
            return (
                <RightEdge
                    {...commonProps}
                    variants={rightVariants}
                >
                    {children}
                </RightEdge>
            );
        case 'bottom':
            return (
                <BottomEdge
                    {...commonProps}
                    variants={bottomVariants}
                >
                    {children}
                </BottomEdge>
            );
        case 'left':
            return (<>
                <LeftEdge
                    {...commonProps}
                    variants={leftVariants($withTabs)}
                >
                    {children}
                </LeftEdge>
                {tab}
            </>
            );
        default:
            return null;
    }
};