import React, { useState } from 'react';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { Button, ButtonField, FilterValueType, LsFilterBuilder, LsFilterBuilderField, LsModal } from '@liasincontrol/ui-devextreme';
import { FilterBuilderProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';
import { styled } from 'styled-components';

/**
 * Represents a UI component that renders a filter builder editor. Only use if editorSettings
 */
export const FilterBuilderEditor: React.FC<FilterBuilderProps> = (props) => {
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);
    const filterButtonName = props.editorSettings?.disabled ? 'Bekijken' : (hasFilterValue(props.value) ? 'Aanpassen' : 'Instellen');

    return (
        <>
            <ButtonField
                id={props.id}
                label={props.label}
                value={{ name: filterButtonName, onReset: props.onReset }}
                name={filterButtonName}
                onReset={props.onReset}
                helpText={props.helpText}
                disabled={props.editorSettings!.disabled}
                onChange={() => setDialogOpened(true)}
                error={getValidationErrorsNode(props.editorSettings!.validationErrors)}
            />
            {dialogOpened && (
                <EditorFilterDialog
                    id={props.id}
                    disabled={props.editorSettings!.disabled}
                    value={hasFilterValue(props.value) ? JSON.parse(props.value) : []}
                    fields={props.fields}
                    variables={props.variables}
                    onSaveChanges={(value: FilterValueType) => {
                        props.editorSettings?.onChange(JSON.stringify(value));
                        setDialogOpened(false);
                    }}
                    onCancelChanges={() => setDialogOpened(false)}
                />
            )}
        </>
    );
};

type DialogProps = {
    id: string,
    fields: LsFilterBuilderField[] | undefined;
    value: FilterValueType,
    variables: IDataItemProps<string>[],
    disabled?: boolean,
    onSaveChanges: (value: FilterValueType) => void,
    onCancelChanges: () => void
};

const EditorFilterDialog: React.FC<DialogProps> = (props) => {
    const [value, setValue] = useState<FilterValueType>(props.value);
    return (
        <LsModal
            id='modal-filter-builder'
            title="Filter configureren"
        >
            {props.fields?.length > 0 &&
                <>
                    <LsFilterBuilder
                        id={props.id}
                        fields={props.fields}
                        value={value}
                        onValueChanged={setValue}
                        disabled={props.disabled}
                        variables={props.variables}
                    />
                    <Toolbar>
                        <Button type='normal' stylingMode='text' text='Annuleren' onClick={() => props.onCancelChanges()} />
                        <Button type='default' stylingMode='contained' text={!props.disabled ? 'Opslaan' : null} onClick={() => props.onSaveChanges(value || [])} />
                    </Toolbar>
                </>
            }
        </LsModal>
    );
};

// necessary for BUG 29490
const Toolbar = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
`;

const hasFilterValue = (filterValue: {}) => filterValue && filterValue !== 'null' && filterValue !== '[]' && filterValue !== '';
