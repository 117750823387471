import { AxiosResponse } from "axios";
import HttpClient from "@liasincontrol/http-service";
import * as Domain from "@liasincontrol/domain";
import { DataAccessHostUtils } from "../../Shared/DataAccessHostUtils";

export class HierarchyTaskDataAccessor {
  /**
   * Get a list of Guids that represent the assigned users for that studio hierarchy item in a specific workflow state.
   *
   * @param hierarchyId Id (GUID) of the studio hierarchy.
   * @param itemId Id (GUID) of the item.
   * @param workflowStateId Id (GUID) of the Workflow state.
   */
  public static get = async (hierarchyId: string, itemId: string, workflowStateId: string): Promise<AxiosResponse<string[]>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();
    return HttpClient.get<string[]>(`/api/studio/hierarchy/${hierarchyId}/item/${itemId}/state/${workflowStateId}/tasks/users`, config);
  };

  /**
   * Sets the assigned users for a studio hierarchy item in a specific workflow state.
   *
   * @param hierarchyId Id (GUID) of the studio hierarchy.
   * @param studioHierarchyItemId Id (GUID) of the item.
   * @param studioHierarchyWorkflowStateId Id (GUID) of the Workflow state.
   * @param userIds List of user Id's (GUID) to be assigned.
   */
  public static assignUsers = async (
    hierarchyId: string,
    studioHierarchyItemId: string,
    studioHierarchyWorkflowStateId: string,
    userIds: string[]
  ): Promise<AxiosResponse<Domain.Studio.UpdatedHierarchyContributors>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();

    const postedData = {
      studioHierarchyItemId,
      studioHierarchyWorkflowStateId,
      userIds,
    };
    return HttpClient.post<Domain.Studio.UpdatedHierarchyContributors>(`/api/studio/hierarchy/${hierarchyId}/tasks/set-user-assignments`, postedData, config);
  };
}
