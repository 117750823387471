import { License, UserIdentity } from '@liasincontrol/auth-service';
import * as Domain from '@liasincontrol/domain';

enum ActionType {
    Create = 0,
    Read = 1,
    Update = 2,
    Delete = 3,
}

enum Actions {
    None,
    // Global
    CRUD_UsersAndGroups,
    CRUD_Roles,
    CRUD_DataStores,
    CRUD_Workflows,
    CRUD_MeasureMoments,

    // Publisher
    CRUD_Publications,
    CRUD_Sitemap,
    CRUD_Datasources,
    CRUD_Templates,
    CRUD_ManagePublishProfiles,
    COMPLEX_PublishPublication,
    COMPLEX_PublisherSetPublishProfileItems,
    COMPLEX_RefreshDataSource,
    COMPLEX_SendPublicationEmail,
    COMPLEX_ManageTasks,
    COMPLEX_TasksOverview,

    // Performance
    CRUD_Performance,
    COMPLEX_ManagePerformanceElementDefinition,
    COMPLEX_CreatePerformanceTopLevelEntity,
    COMPLEX_GlobalPerformanceContributor,
    COMPLEX_ShowDashboardCharts,

    // Studio
    CRUD_Studio,
    CRUD_StudioHierarchies,
    COMPLEX_ManageStudioElementDefinition,
    COMPLEX_ManageStudioHierarchyDefinition,
    COMPLEX_GlobalStudioContributor,
    COMPLEX_CreateStudioTopLevelEntity,

    // Finance
    CRUD_Finance,
    COMPLEX_FinanceBudget,
    CRUD_ManageFinance,
    COMPLEX_ChangeBudgetJournalWorkflowState,
    COMPLEX_FinanceDevelopement,
    COMPLEX_FinanceExportBudgetJournals,

    COMPLEX_ManageStudioTasks
}

enum Features {
    Feature_Demo = "demo",
}
/**
 * Represents a service that is responsible for managing the user permissions.
 * 
 */
class UserRightsService {

    /**
     * Determines if the current user identity has at least one permission from the list, to perform a specific action.
     * 
     * @param userIdentity Defines the user identity.
     * @param actions Defines the lsit of possible action.
     * @param actionType Defines the optional action type.
     * 
     * @returns true if the user has permission on at least one action, and false if not.
     */
    public canPerformAnyAction = (userIdentity: UserIdentity, actions: Actions[], actionType?: ActionType): boolean => {
        for (let index = 0; index < actions.length; index++) {
            if (this.canPerformAction(userIdentity, actions[index], actionType)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Determines if the current user identity has permissions to perform a specific action.
     * 
     * @param userIdentity Defines the user identity.
     * @param action Defines the action.
     * @param actionType Defines the optional action type.
     * 
     * @returns true if the user has the required permissions, and false if not.
     */
    public canPerformAction = (userIdentity: UserIdentity, action: Actions, actionType?: ActionType): boolean => {
        const requiredLicense = this.getRequiredLicense(action);
        if (requiredLicense && !userIdentity?.profile?.licenses?.includes(requiredLicense)) {
            return false;
        }

        //
        // In most cases, the action type doesn't matter. 
        // Very few actions will return different permissions for read/create/update actions on the same action.
        // But for the few actions that need this difference,  
        //

        switch (action) {
            //
            // Global actions
            //
            case Actions.CRUD_UsersAndGroups:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageUsers);
            case Actions.CRUD_Roles:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageRoles);
            case Actions.CRUD_DataStores:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageDataStores);
            case Actions.CRUD_Workflows:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageWorkflows);
            case Actions.CRUD_MeasureMoments:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageMeasureMoments);

            //
            // Publisher actions
            //

            case Actions.CRUD_Publications:
                // All users can read the publications
                if (actionType === ActionType.Read) {
                    return true;
                }
                // A separate permission is quired for create/update/delete.
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublication);
            case Actions.CRUD_Sitemap:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublicationSitemap);
            case Actions.CRUD_Datasources:
                if (actionType === ActionType.Read) {
                    return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublicationDataSources) ||
                        this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.RefreshPublicationDataSources);
                }
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublicationDataSources);
            case Actions.CRUD_Templates:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublicationTemplates);
            case Actions.CRUD_ManagePublishProfiles:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublicationProfiles);
            case Actions.COMPLEX_PublishPublication:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.PublishPublication);
            case Actions.COMPLEX_PublisherSetPublishProfileItems:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ApplyPublicationProfiles);
            case Actions.COMPLEX_RefreshDataSource:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.RefreshPublicationDataSources);
            case Actions.COMPLEX_SendPublicationEmail:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePublication);
            case Actions.COMPLEX_ManageTasks:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageTasks);
            case Actions.COMPLEX_TasksOverview:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.TasksOverview);

            //
            // Performance actions
            //

            case Actions.CRUD_Performance:
                // By default, all users have the permission to create/update/delete items.
                // However, these are further restricted by their contributor rights
                return true;
            case Actions.COMPLEX_ManagePerformanceElementDefinition:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManagePerformanceElementDefinitions);
            case Actions.COMPLEX_CreatePerformanceTopLevelEntity:
            case Actions.COMPLEX_GlobalPerformanceContributor:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.GlobalPerformanceElementsContributer);
            case Actions.COMPLEX_ShowDashboardCharts:
                // for now, everyone should be able to view this dashboard widget.
                return true;

            //
            // Studio actions
            //

            case Actions.CRUD_Studio:
                // By default, all users have the permission to create/update/delete items.
                // However, these are further restricted by their contributor rights
                return true;
            case Actions.CRUD_StudioHierarchies:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageStudioHierarchies);
            case Actions.COMPLEX_ManageStudioElementDefinition:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageStudioElementDefinitions);
            case Actions.COMPLEX_ManageStudioHierarchyDefinition:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageStudioHierarchyDefinitions);
            case Actions.COMPLEX_GlobalStudioContributor:
            case Actions.COMPLEX_CreateStudioTopLevelEntity:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.GlobalStudioElementsContributer);
            case Actions.COMPLEX_ManageStudioTasks:
                return false;

            //
            // Finance actions
            //

            case Actions.CRUD_Finance:
                // All users can read the finance elements
                // if (actionType === ActionType.Read) {
                //     return true;
                // }
                return true;
            // For now, don't allow other access than read to any of the Finance screens
            // return false;
            case Actions.COMPLEX_FinanceBudget:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ViewFinanceBudgetOverview);
            case Actions.CRUD_ManageFinance:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ManageFinance);
            case Actions.COMPLEX_ChangeBudgetJournalWorkflowState:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ChangeBudgetJournalWorkflowState);
            case Actions.COMPLEX_FinanceDevelopement:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ViewFinanceBudgetDevelopment);
            case Actions.COMPLEX_FinanceExportBudgetJournals:
                return this.userHasPermission(userIdentity, Domain.Shared.UserPermissions.ExportBudgetJournal);

            default:
                console.error(`Unknown action ${action}`);
        }

        // For unknown actions, by default allow all read operations.
        if (actionType === ActionType.Read) {
            return true;
        }

        // But don't allow anything else.
        return false;
    };

    /**
     * Determines if the selected feature is available on the tenant.
     * 
     * @param userIdentity Defines the user identity.
     * @param feature Defines the searched feature.
     * 
     * @returns true if the feature is available, and false if not.
     */
    public isAvailableFeature = (userIdentity: UserIdentity, feature: Features): boolean => {
        return userIdentity?.profile?.featureFlags?.includes(feature);
    };

    /**
     * Determines if the selected licence is available on the tenant.
     * 
     * @param userIdentity Defines the user identity.
     * @param licence Defines the searched licence.
     * 
     * @returns true if the licence is available, and false if not.
     */
    public userHasLicence = (userIdentity: UserIdentity, requiredLicense: License): boolean =>
        userIdentity?.profile?.licenses?.includes(requiredLicense);

    private userHasPermission = (userIdentity: UserIdentity, serverPermission: Domain.Shared.UserPermissions): boolean =>
        userIdentity?.profile?.permissions?.includes(serverPermission);


    private getRequiredLicense = (action: Actions): License | null => {

        switch (action) {
            //
            // Global actions
            //
            case Actions.CRUD_UsersAndGroups:
            case Actions.CRUD_Roles:
            case Actions.CRUD_DataStores:
            case Actions.CRUD_Workflows:
            case Actions.CRUD_MeasureMoments:
                return null;

            //
            // Publisher actions
            //

            case Actions.CRUD_Publications:
            case Actions.CRUD_Sitemap:
            case Actions.CRUD_Datasources:
            case Actions.CRUD_Templates:
            case Actions.CRUD_ManagePublishProfiles:
            case Actions.COMPLEX_PublishPublication:
            case Actions.COMPLEX_PublisherSetPublishProfileItems:
            case Actions.COMPLEX_RefreshDataSource:
            case Actions.COMPLEX_SendPublicationEmail:
            case Actions.COMPLEX_ManageTasks:
            case Actions.COMPLEX_TasksOverview:
                return License.Publisher;

            //
            // Performance actions
            //

            case Actions.CRUD_Performance:
            case Actions.COMPLEX_ManagePerformanceElementDefinition:
            case Actions.COMPLEX_CreatePerformanceTopLevelEntity:
            case Actions.COMPLEX_GlobalPerformanceContributor:
            case Actions.COMPLEX_ShowDashboardCharts:
                return License.Performance;

            //
            // Studio actions
            //

            case Actions.CRUD_Studio:
            case Actions.CRUD_StudioHierarchies:
            case Actions.COMPLEX_ManageStudioElementDefinition:
            case Actions.COMPLEX_ManageStudioHierarchyDefinition:
            case Actions.COMPLEX_GlobalStudioContributor:
            case Actions.COMPLEX_CreateStudioTopLevelEntity:
            case Actions.COMPLEX_ManageStudioTasks:
                return License.Studio;

            //
            // Finance actions
            //

            case Actions.CRUD_Finance:
            case Actions.COMPLEX_FinanceBudget:
            case Actions.CRUD_ManageFinance:
            case Actions.COMPLEX_ChangeBudgetJournalWorkflowState:
            case Actions.COMPLEX_FinanceDevelopement:
            case Actions.COMPLEX_FinanceExportBudgetJournals:
                return License.Finance;


            default:
                console.error(`Unknown action ${action} in license check`);
        }

        return null;
    };

    /**
     * Gets an instance of the UserRightsService class.
     *
     * @returns An instance of the UserRightsService class.
     */
    public static getInstance(): UserRightsService {
        if (UserRightsService.instance === null || UserRightsService.instance === undefined) {
            UserRightsService.instance = new UserRightsService();
        }

        return UserRightsService.instance;
    }

    private static instance: UserRightsService;
}

export { UserRightsService, Actions, ActionType, Features, License };
